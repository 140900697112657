<template>
  <div>
    <div class="block" v-for="(photo, index) in photos" :key="photo">
      <div class="q-photo">
        <!-- картинка -->
        <div class="q-photo-img">
          <div v-if="photo.photoThumbnail">
            <img :src="photo.photoThumbnail" :alt="photo.photoName" />
          </div>
          <div v-else>
            <progress
              max="100"
              :value="photo.progress"
              :id="`progress-${question.id}-${photo.photoName}`"
            ></progress>
            <label
              v-if="photo.progress < 100"
              :for="`progress-${question.id}-${photo.photoName}`"
              >{{ Math.round(photo.progress) }}%</label
            >
            <label v-else :for="`progress-${question.id}-${photo.photoName}`">
              сохранение...
            </label>
          </div>
        </div>
        <!-- имя файла -->
        <div class="q-photo-name">
          <div>{{ photo.photoName }}</div>
        </div>
        <!-- кнопка управления -->
        <div class="q-photo-btn">
          <button
            v-if="photo.photoURL"
            @click="handleDeletePhoto(index)"
            :disabled="question.disabled === true"
          >
            <div class="q-photo-icons">
              <img class="q-photo-icons" src="@/assets/delete_black_24dp.svg" />
            </div>
          </button>
          <button
            v-else
            @click="handleCancelUpload(index)"
            :disabled="question.disabled === true || photo.progress == 100"
          >
            <div class="q-photo-icons">
              <img class="q-photo-icons" src="@/assets/cancel_black_24dp.svg" />
            </div>
          </button>
        </div>
      </div>
    </div>
    <div>
      <div class="q-photo-upload">
        <label class="q-photo-clickarea">
          <div class="q-photo-icons">
            <div class="textfile">Добавить файл</div>
            <img
              class="q-photo-icons"
              src="@/assets/attach_file_black_24dp.svg"
              alt="выбрать файл"
            />
          </div>
          <input
            type="file"
            accept="image/*"
            :id="`file-${question.id}`"
            @change="
              (event) => {
                handlePhotoSelect(event, question);
              }
            "
            :disabled="question.disabled === true"
          />
        </label>
      </div>
    </div>
    <div class="error" v-if="errorMessage">{{ errorMessage }}</div>
    <div class="error" v-if="question.disabled === true">
      Нет соединения с интеретом!
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import firebase from "firebase/app";
import { projectFunctions, timestamp } from "@/firebase/config";
import firebaseCredential from "@/composables/firebaseCredential";
import useStorage from "@/composables/useStorage";
import useCollection from "@/composables/useCollection";
export default {
  name: "QPhoto",
  props: {
    question: ref({}),
  },
  setup(props) {
    const store = useStore();
    const { user } = firebaseCredential();
    const { storageRef, deleteFile } = useStorage(
      `photos/audits/${store.getters.formTypeId}/${store.getters.formUUID}/${props.question.id}`
    );
    const { addDoc } = useCollection("photos_audits");

    const createThumbnail = projectFunctions.httpsCallable("createThumbnail");

    const photos = ref([]);
    const errorMessage = ref(null);

    let answer = null;
    answer = store.getters.answers[props.question.id];
    if (!answer) {
      answer = [];
    } else {
      for (let i in answer) {
        photos.value.push(answer[i]);
      }
    }

    const updateAnswer = () => {
      let answer = [];
      for (let i = 0; i < photos.value.length; i++) {
        if (
          photos.value[i]?.photoName &&
          photos.value[i]?.photoPath &&
          photos.value[i]?.photoURL &&
          photos.value[i]?.photoThumbnail
        ) {
          answer.push({
            photoName: photos.value[i].photoName,
            photoPath: photos.value[i].photoPath,
            photoURL: photos.value[i].photoURL,
            photoThumbnail: photos.value[i].photoThumbnail,
          });
        }
      }
      store.dispatch("saveAnswer", {
        qid: props.question.id,
        value: answer,
      });
    };

    const uploadPhoto = async (file) => {
      let photoIndex = photos.value.length;
      let uploadTask = storageRef
        .child(file.name)
        .put(file, { customMetadata: { author: `${user.value.uid}` } });
      // add new photo
      photos.value.push({
        photoName: file.name,
        photoPath: null,
        photoURL: null,
        uploadTask: uploadTask,
      });
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => {
          photos.value[photoIndex].progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              break;
          }
        },
        (error) => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;
            case "storage/canceled":
              // User canceled the upload
              break;
            // ...
            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
          // console.log(error);
          errorMessage.value = error.message;
        },
        async () => {
          try {
            let downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
            let fullPath = uploadTask.snapshot.ref.fullPath;
            // get thumbnail
            let result = await createThumbnail({ filePath: fullPath });
            let fileRef = storageRef.child(result.data.fileName);
            let thumbnailURL = await fileRef.getDownloadURL();

            photos.value[photoIndex].photoPath = fullPath;
            photos.value[photoIndex].photoURL = downloadURL;
            photos.value[photoIndex].photoThumbnail = thumbnailURL;
            // write in firestore
            let fileName = uploadTask.snapshot.ref.name;
            await addDoc({
              qid: props.question.id,
              formUUID: store.getters.formUUID,
              author_uid: user.value.uid,
              createdAt: timestamp(),
              photoName: fileName,
              photoPath: fullPath,
              photoURL: downloadURL,
              photoThumbnail: thumbnailURL,
              photoSize: uploadTask.snapshot.totalBytes,
            });
            // save answer
            updateAnswer();
          } catch (error) {
            errorMessage.value = error.message;
          }
        }
      );
    };

    const handleDeletePhoto = async (index) => {
      errorMessage.value = null;
      if (index != null) {
        Promise.all([deleteFile(photos.value[index].photoName)]).then(
          () => {
            photos.value.splice(index, 1);
            updateAnswer();
          },
          (reason) => {
            errorMessage.value = reason.message;
            photos.value.splice(index, 1);
            updateAnswer();
          }
        );
      }
    };
    const handleCancelUpload = async (index) => {
      errorMessage.value = null;
      try {
        await photos.value[index].uploadTask.cancel();
        photos.value.splice(index, 1);
      } catch (error) {
        errorMessage.value = error.value;
      }
    };

    const handlePhotoSelect = async (event, question) => {
      errorMessage.value = null;
      let file = event.target.files[0];
      if (file) {
        if (photos.value.length < question.maxlength) {
          if (file.type.substring(0, 6) == "image/") {
            let isNew = true;
            for (let i = 0; i < photos.value.length; i++) {
              if (photos.value[i].photoName == file.name) {
                isNew = false;
              }
            }
            if (isNew) {
              question.error = null;
              uploadPhoto(file);
            } else {
              errorMessage.value = "Фото с таким именем уже выбранно.";
            }
          } else {
            errorMessage.value = "Недопустимый тип файла!";
          }
        } else {
          errorMessage.value = "Максимально допустимое количество фотографий!";
        }
        event.target.value = null;
      }
    };

    return {
      photos,
      errorMessage,
      handlePhotoSelect,
      handleDeletePhoto,
      handleCancelUpload,
    };
  },
};
</script>

<style></style>
