<template>
  <div v-for="question in questions" :key="question">
    <Question
      :question="question"
      :readonly="isReadonly(question.id)"
      v-if="!question.invisible"
    />
    <QuestionsList
      :questions="question.questions"
      v-if="question.answer_value == answers[question.id]"
    />
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import Question from "./Question.vue";
export default {
  name: "QuestionsList",
  props: {
    questions: ref([]),
  },
  components: { Question },
  setup() {
    const store = useStore();
    const answers = ref([]);
    answers.value = store.getters.answers;
    const isReadonly = (qid) => {
      let readonlyQuestions = ["sales_point_title", "sales_point_address"];
      if (
        readonlyQuestions.includes(qid) &&
        store.getters.answers["sales_point_code"]
      ) {
        return true;
      }
      return false;
    };
    return { answers, isReadonly };
  },
};
</script>

<style></style>
