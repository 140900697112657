<template>
  <div class="q" :id="question.id">
    <!-- заголовок -->
    <div class="header-block" v-if="question.header">
      <h3>{{ question.header }}</h3>
    </div>
    <div class="block">
      <div class="q-title">
        {{ question.title }}
        <span class="q-required" v-if="question.required">*</span>
      </div>
      <div class="error" v-if="question.error">{{ question.error }}</div>
      <div class="q-description">{{ question.description }}</div>
      <!-- строка single line -->
      <div v-if="question.type == `input-text`">
        <input
          type="text"
          placeholder="Мой ответ"
          autocomplete="off"
          v-model="answer"
          :name="question.id"
          :readonly="readonly === true"
          :required="question.required === true && !answer"
          @change="handleOnChange(question, answer)"
        />
      </div>
      <!-- число -->
      <div v-else-if="question.type == `input-number`">
        <input
          type="number"
          placeholder="Мой ответ"
          autocomplete="off"
          v-model="answer"
          :name="question.id"
          :required="question.required === true && !answer"
          @change="handleOnChange(question, answer)"
        />
      </div>
      <!-- строка multi line -->
      <div v-else-if="question.type == `input-text-multiline`">
        <textarea
          placeholder="Мой ответ"
          autocomplete="off"
          v-model="answer"
          :required="question.required === true && !answer"
          :rows="!question.rows ? 3 : question.rows"
          :maxlength="question.maxlength"
          @change="handleOnChange(question, answer)"
        ></textarea>
      </div>
      <!-- фотографии -->
      <div v-else-if="question.type == `photos-list`">
        <QPhoto :question="question" />
      </div>
      <!-- варианты ответов -->
      <div v-else-if="question.type == `select-one`">
        <div
          class="q-variant"
          v-for="variant in question.variants"
          :key="variant"
        >
          <input
            type="radio"
            v-model="answer"
            :name="question.id"
            :value="variant.value"
            :id="`${question.id}_${variant.value}`"
            @change="handleOnChange(question, answer)"
          />
          <label :for="`${question.id}_${variant.value}`">
            {{ variant.title }}
          </label>
        </div>
      </div>
      <div v-else-if="question.type == `select-many`">
        <div
          class="q-variant"
          v-for="variant in question.variants"
          :key="variant"
        >
          <input
            type="checkbox"
            v-model="answer[variant.value]"
            :name="`${question.id}_${variant.value}`"
            :id="`${question.id}_${variant.value}`"
            @change="handleOnChange(question, answer)"
          />
          <label :for="`${question.id}_${variant.value}`">
            {{ variant.title }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import QPhoto from "@/components/QPhoto.vue";
export default {
  name: "Question",
  props: {
    question: ref({}),
    readonly: ref(false),
  },
  components: { QPhoto },
  setup(props) {
    const store = useStore();
    const answer = ref(null);
    answer.value = store.getters.answers[props.question.id];
    if (props.question.type == "select-many") {
      if (!answer.value) {
        answer.value = {};
      }
    }
    const highlightAnswerVariant = (question) => {
      if (question.type == "select-one" || question.type == "select-many") {
        let divs = document
          .getElementById(question.id)
          .getElementsByClassName("q-variant");
        for (let i = 0; i < divs.length; i++) {
          if (divs[i].children[0].checked) {
            divs[i].classList.add("q-variant-highlight");
          } else {
            divs[i].classList.remove("q-variant-highlight");
          }
        }
      }
    };
    const handleOnChange = (question, answer) => {
      question.error = null;
      store.dispatch("saveAnswer", { qid: question.id, value: answer });
      highlightAnswerVariant(question);
    };
    return { answer, highlightAnswerVariant, handleOnChange };
  },
  mounted() {
    this.highlightAnswerVariant(this.question);
  },
};
</script>

<style></style>
