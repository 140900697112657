import { projectStorage } from "@/firebase/config";

const useStorage = (path) => {
  const storageRef = projectStorage.ref(path);

  const deleteFile = async (fileName) => {
    let fileRef = storageRef.child(fileName);
    await fileRef.delete();
  };
  return { storageRef, deleteFile };
};

export default useStorage;
