<template>
  <div class="audit-form">
    <div class="loader-center" v-if="pendingQuestions">
      <LoaderCircular />
    </div>
    <div v-else>
      <div class="header-block">
        <h3>{{ formHeader }}</h3>
      </div>
      <div v-if="!formSubmitted">
        <div class="q-list">
          <QuestionsList :questions="questions" />
        </div>
        <div class="audit-form-submit">
          <button
            :disabled="
              onLine === false ||
              formSubmitting === true ||
              questions.length == 0
            "
            @click="handleSubmitAnswers"
          >
            Отправить
          </button>
          <LoaderCircular v-if="formSubmitting" size="34px" />
        </div>
        <div class="error" v-if="errorMessage">{{ errorMessage }}</div>
      </div>
      <div v-else>
        <div class="form-complete">Анкета успешно оправлена.</div>
        <router-link class="btn" to="/">Новая анкета</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import useApi from "@/composables/useApi";
import QuestionsList from "@/components/QuestionsList.vue";
import LoaderCircular from "@/components/LoaderCircular";
export default {
  components: {
    QuestionsList,
    LoaderCircular,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const { buildForm, submitAnswers } = useApi();

    const questions = ref([]);
    const formHeader = ref("");
    const errorMessage = ref(null);

    const pendingQuestions = ref(true);
    const formSubmitting = ref(false);
    const formSubmitted = ref(false);
    const onLine = ref(navigator.onLine);

    const checkAnswers = (questionsList, answers) => {
      let errorsList = [];
      for (let i = 0; i < questionsList.length; i++) {
        let question = questionsList[i];
        let answer = answers[question.id];
        if (question.required == true) {
          question.error = null;
          if (!answer && !(answer === false || answer == 0)) {
            errorsList.push({
              qid: question.id,
              message: "Обязательно для заполнения!",
            });
          } else {
            if (Array.isArray(answer) && answer.length == 0) {
              errorsList.push({
                qid: question.id,
                message: "Обязательно для заполнения!",
              });
            }
          }
        }
        if (question.questions && question.answer_value == answer) {
          errorsList = errorsList.concat(
            checkAnswers(question.questions, answers)
          );
        }
      }
      return errorsList;
    };

    const highlightErrors = (questionsList, errorsList) => {
      for (let i = 0; i < questionsList.length; i++) {
        let question = questionsList[i];
        let errorAnswer = null;
        for (let j = 0; j < errorsList.length; j++) {
          if (errorsList[j].qid == question.id) {
            errorAnswer = errorsList[j];
            break;
          }
        }
        if (errorAnswer != null) {
          question.error = errorAnswer.message;
        }
        if (question.questions) {
          highlightErrors(question.questions, errorsList);
        }
      }
    };
    const handleSubmitAnswers = async () => {
      errorMessage.value = null;
      formSubmitting.value = true;
      let formIsStarted = await store.dispatch("formIsStarted");
      if (formIsStarted === true) {
        let answers = store.getters.answers;
        let errorsList = checkAnswers(questions.value, answers);
        if (errorsList.length > 0) {
          highlightErrors(questions.value, errorsList);
          errorMessage.value = "* Необходимо заполнить все обязательные поля";
          formSubmitting.value = false;
        } else {
          navigator.geolocation.getCurrentPosition(
            async (position) => {
              let geoEnd = {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
              };
              submitAnswers(
                store.getters.formTypeId,
                store.getters.formUUID,
                store.getters.territoryId,
                store.getters.geoStart,
                geoEnd,
                answers
              ).then(
                (data) => {
                  if (data.result.success === true) {
                    formSubmitted.value = true;
                    store.dispatch("closeForm");
                  } else {
                    let answersErrors = data.result.answersErrors;
                    highlightErrors(questions.value, answersErrors);
                    errorMessage.value = data.result.message;
                  }
                  formSubmitting.value = false;
                },
                (reason) => {
                  errorMessage.value = reason.message;
                  formSubmitting.value = false;
                }
              );
            },
            (reason) => {
              errorMessage.value = reason.message;
              formSubmitting.value = false;
            }
          );
        }
      } else {
        errorMessage.value = "Ошибка отправки анкеты! Анкета была отменена.";
        formSubmitting.value = false;
      }
    };
    // global events
    const handleOnMounted = async () => {
      pendingQuestions.value = true;
      errorMessage.value = null;
      if (
        store.getters.formTypeId != null &&
        store.getters.territoryId != null
      ) {
        let formIsStarted = await store.dispatch("formIsStarted");
        if (formIsStarted) {
          buildForm(store.getters.formTypeId).then(
            (data) => {
              questions.value = data.result.questions;
              formHeader.value = data.result.title;
              pendingQuestions.value = false;
            },
            (reason) => {
              errorMessage.value = reason.message;
              pendingQuestions.value = false;
            }
          );
        } else {
          pendingQuestions.value = false;
          router.push({ name: "Home" });
        }
      }
    };
    const updateOnlineStatus = (event) => {
      const { type } = event;
      onLine.value = type === "online";
    };
    const updatePhotoAvailability = (questionsList, isOnline) => {
      for (let i = 0; i < questionsList.length; i++) {
        let question = questionsList[i];
        if (question.type == "photos-list") {
          question.disabled = !isOnline;
        }
        if (question.questions) {
          updatePhotoAvailability(question.questions, isOnline);
        }
      }
    };

    return {
      formHeader,
      questions,
      formSubmitting,
      formSubmitted,
      pendingQuestions,
      onLine,
      errorMessage,
      handleOnMounted,
      handleSubmitAnswers,
      updateOnlineStatus,
      updatePhotoAvailability,
    };
  },
  watch: {
    onLine(value) {
      if (value && this.questions.length == 0) {
        this.handleOnMounted();
      }
      this.updatePhotoAvailability(this.questions, value);
    },
  },
  mounted() {
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);

    this.handleOnMounted();
  },
  beforeUnmount() {
    window.removeEventListener("online", this.updateOnlineStatus);
    window.removeEventListener("offline", this.updateOnlineStatus);
  },
};
</script>

<style></style>
