import { projectFirestore } from "@/firebase/config";

const useCollection = (collectionName) => {
  const addDoc = async (doc) => {
    return await projectFirestore.collection(collectionName).add(doc);
  };
  const deleteDoc = async (fileFullPath) => {
    let res = await projectFirestore
      .collection(collectionName)
      .where("photoPath", "==", fileFullPath)
      .limit(1)
      .get();
    res.forEach((doc) => {
      projectFirestore.collection(collectionName).doc(doc.id).delete();
    });
  };
  return {
    addDoc,
    deleteDoc,
  };
};

export default useCollection;
